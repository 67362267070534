import { TronLinkAdapter } from '@tronweb3/tronwallet-adapter-tronlink'
import TronWeb from 'tronweb'
import { toNonExponential, divide, pow } from '@/utils/calculation'
import { delay } from '@/utils/common'
import { ConnectWalletError, BalanceNotEnough } from './errors'
import { APIResponse, RESPONSE_TYPE } from './response'

export default class TronAdapter {
  constructor({ chainId, wallet, options = {} }) {
    this.wallet = wallet // 钱包类型
    // 钱包初始化参数
    this.options = {
      ...options,
    }
    this.provider = null // 提供者
    this.accountInfo = null // 账户信息
    this.chainId = chainId
    this.tronWeb = this.buildTronWeb()
  }

  // 创建provider
  createProvider() {
    return new TronLinkAdapter()
  }

  // 连接
  async connect() {
    try {
      this.provider = this.createProvider()
      if (!this.provider) return
      // await this.provider.connect()
      await this.connectProvider()
      this.tronWeb = this.buildTronWeb()
      await this.switchNetwork(this.chainId)
    } catch (e) {
      console.log('connect', e)
      throw new ConnectWalletError(e?.message || e)
    }
  }

  /* 连接钱包的动作，兼容tronLink和OneKey */
  async connectProvider() {
    if (this._isOneKey()) {
      if (!window.$onekey.tron.tronWeb.provider.ready) {
        await window.$onekey.tron.request({ method: 'tron_requestAccounts' })
      }
      try {
        await this.provider.connect()
      } catch (error) {
        // console.log("🚀 ~ onekey 连接tron钱包报错，不影响后面程序 ~ error:", error)
      }
    } else {
      console.log('非onekey')
      await this.provider.connect()
    }
  }

  async send(method, params) {
    try {
      const request =
        this.provider._wallet._client?.request || this.provider._wallet.request
      if (this._isTronLink()) {
        return request({
          method,
          params,
        })
      }
    } catch (e) {
      console.log('send', e)
      throw e
    }
  }

  // 切换网络
  async switchNetwork(chainId) {
    try {
      console.log('switchNetwork chainId before', chainId)
      if (this._isOneKey()) {
      } else {
        await this.send('wallet_switchEthereumChain', [{ chainId }])
      }
      await delay(1000)
      await this.getAccountInfo()
      console.log('switchNetwork chainId after', this.accountInfo.chainId)
    } catch (switchError) {
      throw switchError
    }
  }

  // tronweb方法初始化
  buildTronWeb() {
    const isShasta = this.chainId === '0x94a9059e'
    const options = {
      fullHost: isShasta
        ? 'https://api.shasta.trongrid.io'
        : 'https://api.trongrid.io',
    }
    if (!isShasta) {
      options.headers = {
        'TRON-PRO-API-KEY': this.options?.tronApikey,
      }
    }
    if (this.wallet === 'TronLink') {
      return window.tronWeb
    } else {
      const tronWeb = new TronWeb(options)
      const tmpAddress =
        this.provider?.address || 'TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t'
      tronWeb.setAddress(tmpAddress)
      return tronWeb
    }
  }

  // 获取用户信息
  async getAccountInfo() {
    try {
      const data = {
        account: this.provider.address || '',
        chainId: '',
      }
      if (this._isTronLink()) {
        // 初次连接需要重复调用一次
        if (!this.provider._wallet.tronWeb) {
          return await this.connect()
        }
        const currentNetwork = await this.provider.network()
        if (currentNetwork?.chainId) {
          data.chainId = currentNetwork?.chainId
        }
      } else {
        if (this.provider._wallet?._network) {
          data.chainId = this.provider._wallet?._network.replace('tron:', '')
        }
      }
      this.accountInfo = data
    } catch (e) {
      throw e
    }
  }

  // 断开连接
  async disconnect() {
    this.provider?.disconnect() // 断开连接
    this.provider = null // 提供者
    this.accountInfo = null // 账户信息
  }

  _isTronLink() {
    return this.wallet === 'TronLink'
  }

  _isOneKey() {
    // return this.tronWeb?.provider?.isOneKey
    return window.$onekey?.tron?.isOneKey
  }

  // 签名
  async signMessage(message) {
    try {
      const result = await this.provider?.signMessage(message)
      return result
    } catch (err) {
      throw err
    }
  }

  // 获取代币小数位
  async getDecimals(tokenAddress) {
    try {
      const contract = await this.tronWeb.contract().at(tokenAddress)
      const decimals = await contract.methods.decimals().call()
      return this.tronWeb.toDecimal(decimals)
    } catch (error) {
      throw error
    }
  }

  // 获取代币余额
  async getTokenBalance(tokenAddress, address) {
    if (!this.tronWeb) return null
    try {
      const contract = await this.tronWeb.contract().at(tokenAddress)
      const balance = await contract.methods.balanceOf(address).call()
      const decimals = await contract.methods.decimals().call()
      return toNonExponential(
        divide(
          this.tronWeb.toDecimal(balance),
          pow(10, this.tronWeb.toDecimal(decimals))
        )
      )
    } catch (error) {
      throw error
    }
  }

  // 查询津贴
  async getAllowance(tokenAddress, from, address) {
    try {
      const contract = await this.tronWeb.contract().at(tokenAddress)
      const allowance = await contract.methods.allowance(from, address).call()
      let resultAllowance = ''
      try {
        resultAllowance = this.tronWeb.toDecimal(allowance)
      } catch (error) {
        resultAllowance = allowance.toString()
      }
      return resultAllowance
    } catch (error) {
      console.log('🚀 getAllowance ~ error:', tokenAddress, error)
      throw error
    }
  }

  // 签名交易
  async signTransaction(transaction, maxAttempts = 3) {
    let res = null
    for (let i = 0; i < maxAttempts; i++) {
      res = this._isTronLink()
        ? await this.tronWeb.trx.sign(transaction)
        : await this.provider.signTransaction(transaction)
      if (res) break
    }
    console.log('signTransaction', res)
    return res
  }

  // 调合约方法
  async sendContractMethod(address, functionSelector, parameter, abi) {
    try {
      let transaction = null
      const feeLimit = 150_000_000
      const from = this.accountInfo.account

      if (this._isTronLink()) {
        const contract = abi
          ? await this.tronWeb.contract(abi, address)
          : await this.tronWeb.contract().at(address)
        console.log('contractcontract', contract)
        const params = parameter.map((item) => item.value)
        transaction = await contract.methods[functionSelector](...params).send({
          feeLimit,
          from,
        })
        console.log('transaction', transaction)
      } else {
        const tx = await this.tronWeb.transactionBuilder.triggerSmartContract(
          address,
          functionSelector,
          {
            feeLimit,
          },
          parameter,
          from
        )
        console.log('tx', JSON.stringify(tx))
        const signedTx = await this.signTransaction(tx.transaction)
        if (!signedTx) return null
        console.log('signedTx', signedTx)
        const rowTransaction = await this.tronWeb.trx.sendRawTransaction(
          signedTx
        )
        if (rowTransaction?.result && rowTransaction?.txid) {
          transaction = rowTransaction.txid
        }
        console.log('transaction', transaction)
      }
      if (transaction) {
        const flag = await this.pollTxidStatus(transaction)
        return flag ? transaction : false
      }
      return false
    } catch (error) {
      throw error
    }
  }

  // 申请津贴
  async sendApprove({ tokenAddress, from, address, value }) {
    try {
      const functionSelector = 'approve(address,uint256)'
      const parameter = [
        { type: 'address', value: address },
        { type: 'uint256', value },
      ]
      return await this.sendContractMethod(
        tokenAddress,
        functionSelector,
        parameter
      )
    } catch (error) {
      throw error
    }
  }

  // 直接转账
  async transfer({ address, tokenAddress, amount }, promiseEvent) {
    const apiResponse = new APIResponse('transfer')
    try {
      await delay(0) // 处理第一次emit不触发问题
      if (!this.tronWeb || !this.accountInfo || !promiseEvent.eventEmitter) {
        throw new Error('invalid provider')
      }
      const from = this.accountInfo.account
      const balance = await this.getTokenBalance(tokenAddress, from)
      if (lt(balance, amount)) {
        // 余额不足
        throw new BalanceNotEnough('balance not enough')
      }
      const { eventEmitter, resolve } = promiseEvent
      eventEmitter.emit('status', apiResponse.ok(RESPONSE_TYPE.AUTH))

      const decimals = await this.getDecimals(tokenAddress)
      const value = mul(amount, pow(10, decimals))

      const functionSelector = 'transfer(address,uint256)'
      const parameter = [
        { type: 'address', value: address },
        { type: 'uint256', value },
      ]
      const txId = await this.sendContractMethod(
        tokenAddress,
        functionSelector,
        parameter
      )
      if (txId) {
        eventEmitter.emit(
          'status',
          apiResponse.ok(RESPONSE_TYPE.SUCCESS, {
            txId,
          })
        )
      } else {
        eventEmitter.emit('error', apiResponse.fail(RESPONSE_TYPE.FAILURE), {
          txId,
        })
      }
      resolve(txId || false)
    } catch (error) {
      console.log('error', error)
      promiseEvent.eventEmitter.emit('error', apiResponse.error(error))
      promiseEvent.reject(error)
    }
  }

  // 校验格式是否正确
  isValidAddress(address) {
    return TronWeb.utils.crypto.isAddressValid(address)
  }

  // 轮询txid方法
  async pollTxidStatus(txHash, maxAttempts = 50, waitInterval = 5000) {
    return new Promise(async (resolve, reject) => {
      await delay(1000)
      let attempts = 0
      while (attempts < maxAttempts) {
        try {
          const txInfo = await this.tronWeb.trx.getTransaction(txHash)
          console.log('tx', txHash, txInfo)
          if (txInfo) {
            switch (txInfo.ret[0].contractRet) {
              case 'SUCCESS':
                resolve(true)
                return
              case 'REVERT':
              case 'OUT_OF_ENERGY':
              case 'OUT_OF_TIME':
                resolve(false)
                return
            }
          }
          await delay(waitInterval)
          attempts++
        } catch (error) {
          // 忽略网络错误等
          await delay(waitInterval)
          attempts++
        }
      }
      resolve(null)
    })
  }
}
